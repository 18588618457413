class Sum {
  public x: number;

  public y: number;

  public xy: number;

  public x2: number;

  public y2: number;

  constructor(x: number, y: number, xy: number, x2: number, y2: number) {
    this.x = x;
    this.y = y;
    this.xy = xy;
    this.x2 = x2;
    this.y2 = y2;
  }
}

export default Sum;
